import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Divider from "@mui/material/Divider";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { ExpandMore } from "@mui/icons-material";

export default function RouteNavigate({
  coursName,
  cours,
  searchOption,
  setSearchOption,
}) {
  return (
    <Box
      sx={{
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 99,
        transition: "all 0.5s",
        boxSizing: "border-box",
        marginLeft: 2,
        paddingTop: 2.5,
      }}
    >
      <Accordion disableGutters disabled={!cours}>
        <AccordionSummary
          expandIcon={<ExpandMore sx={{ color: "#FFF" }} />}
          sx={{
            backgroundColor: "#2962ff",
            color: "#FFF",
            fontWeight: 700,
          }}
        >
          {cours ? `${coursName} 경로안내` : "산책로를 선택하세요"}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0,
            maxHeight: "85vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack direction="row" mt={2} mb={1} px={2} spacing={1}>
            {[
              { title: "추천", value: "0" },
              { title: "큰길 우선", value: "4" },
              { title: "최단거리", value: "10" },
              { title: "계단 제외", value: "30" },
            ].map((option, idx) => (
              <Chip
                key={idx}
                label={option.title}
                onClick={() => {
                  setSearchOption(option.value);
                }}
                sx={{
                  backgroundColor:
                    searchOption === option.value ? "#2962ff" : "none",
                  color: searchOption === option.value ? "#FFF" : "#000",
                  ":hover": {
                    backgroundColor:
                      searchOption === option.value ? "#2962ff" : "none",
                    color: searchOption === option.value ? "#FFF" : "none",
                  },
                }}
              />
            ))}
          </Stack>
          <Stack
            direction="column"
            divider={<Divider orientation="horizontal" flexItem />}
            sx={{
              flex: 1,
              overflow: "scroll",
            }}
            spacing={2}
            px={2}
          >
            {cours
              .filter((poi) => poi.geometry.type === "Point")
              .map((item, idx) => {
                return (
                  <Box
                    key={idx}
                    sx={{
                      boxSizing: "border-box",
                      width: 300,
                      ":nth-of-type(1) > p": {
                        fontWeight: 700,
                        pt: 2.5,
                      },
                      ":nth-last-of-type(1) > p": {
                        fontWeight: 700,
                        pb: 2.5,
                      },
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        width: "100%",
                        wordBreak: "break-all",
                      }}
                    >
                      {`${idx + 1}. ${item.properties?.description
                        .replaceAll(" 에서", "에서")
                        .replaceAll(" 을", "를")}`}
                    </Typography>
                  </Box>
                );
              })}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
