import { Box } from "@mui/system";
import { useEffect, useRef } from "react";

const { Tmapv2 } = window;

export default function MapView({ mapInstance, setMapInstance }) {
  const mapRef = useRef(null);

  useEffect(() => {
    function initTmap() {
      if (mapRef.current?.firstChild || mapInstance) {
        return;
      }
      const map = new Tmapv2.Map("map_div", {
        center: new Tmapv2.LatLng(37.54720768, 127.04743196), // 지도 초기 좌표
        width: "100%",
        height: "100%",
        zoom: 12,
      });
      setMapInstance(map);
    }

    initTmap();
  }, [mapInstance, setMapInstance]);

  return (
    <Box
      sx={{
        backgroundColor: "#ffecb3",
        width: "100%",
        height: "100%",
      }}
    >
      <Box id="map_div" ref={mapRef} />
    </Box>
  );
}
