import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";

export default function DeletePolylineBtn({ disabled, onClick }) {
  return (
    <IconButton
      disabled={disabled}
      sx={{
        position: "absolute",
        right: 20,
        top: 320,
        backgroundColor: "#FFF",
        borderRadius: 2,
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        ":hover": {
          backgroundColor: "#FFF",
        },
      }}
      onClick={onClick}
    >
      <Delete />
    </IconButton>
  );
}
