import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";

import "./fonts/default.css";

import WalkRoadPage from "./pages/WalkRoadPage";

import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

const THEME = createTheme({
  typography: {
    fontFamily: `"Inter", "Pretendard", "Helvetica", "Arial", sans-serif`,
  },
});

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <Router>
        <Routes>
          <Route path="/" exact element={<WalkRoadPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
