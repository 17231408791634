// stretNm        // 길명
// stretIntrcn    // 길소개
// stretLt		    // 총길이
// reqreTime		  // 총소요시간
// beginSpotNm		// 시작지점명
// beginRdnmadr		// 시작지점도로명주소
// beginLnmadr		// 시작지점소재지지번주소
// endSpotNm		  // 종료지점명
// endRdnmadr		  // 종료지점소재지도로명주소
// endLatitude		// 종료지점소재지지번주소
// coursInfo		  // 경로정보
// phoneNumber		// 관리기관전화번호
// institutionNm	// 관리기관명
// referenceDate	// 데이터기준일자
// instt_code		  // 제공기관코드
// instt_nm		    // 제공기관기관명
// const roadData = [
//   {
//     stretNm: "송정둑길",
//     stretIntrcn:
//       "무료로 개방되는 어린이대공원을 후문으로 들어가 한 바퀴 돈 후 정문으로 나가게 된다. 이름 그대로 아이들과 함께 가도 아주 좋을만한 코스이다. 이후 군자교 부근에서 시작되는 송정동 둑길을 지난다. 옆으로 동부간선도로를 달리는 자동차 소리가 들리기는 하지만 높이 솟은 가로수들이 시원한 그늘을 드리우고 있어 한여름에도 걷기가 나쁘지 않다. 또한 중반 무렵 화장실 왼쪽으로 시작되는 은행나무길은 울창한 숲 속에 들어와 있는 착각마저 불러일으킨다. 이후 조선시대에 가장 긴 다리였다는 살곶이다리를 건너 중랑천길을 잠시 걸어 응봉역으로 진입한다.",
//     stretLt: 8.7,
//     reqreTime: "2시간30분",
//     beginSpotNm: "지하철 5호선 아차산역 4번 출입구",
//     beginRdnmadr: "서울특별시 광진구 천호대로 지하 657",
//     beginLnmadr: null,
//     endSpotNm: "중앙선 응봉역 2번 출구",
//     endRdnmadr: "서울특별시 성동구 고산자로 123",
//     endLatitude: null,
//     coursInfo: "아차산역~어린이대공원~송정동 둑길~살곶이다리~응봉역",
//     phoneNumber: "02-2286-5656",
//     institutionNm: "서울특별시 성동구청",
//     referenceDate: "2023-08-08",
//   },
//   {
//     stretNm: "중랑천길",
//     stretIntrcn:
//       "무료로 개방되는 어린이대공원을 후문으로 입장해 산책하듯 거닐다 정문으로 나가게 된다. 이름 그대로 아이들과 함께 가도 좋을만한 코스이다. 이후 군자교 부근에서 시작되는 송정동 둑길을 지나 조선시대에 가장 긴 다리였다는 살곶이다리를 건너 응봉역을 거쳐 응봉공원으로 오른다. 응봉공원 전망대에서는 서울숲과 함께 한강 일대가 조망되어 야경 촬영지로도 명성이 높다. 이후 걷게 되는 서울숲은 115만㎡에 5개의 테마공원과 다양한 볼거리를 갖추고 내방객을 기다린다.",
//     stretLt: 12.8,
//     reqreTime: "4시간",
//     beginSpotNm: "지하철 5호선 아차산역 4번 출입구",
//     beginRdnmadr: "서울특별시 광진구 천호대로 지하 657",
//     beginLnmadr: null,
//     endSpotNm: "지하철 2호선 뚝섬역 8번 출입구",
//     endRdnmadr: "서울특별시 성동구 아차산로 18",
//     endLatitude: null,
//     coursInfo:
//       "아차산역~어린이대공원~송정동 둑길~살곶이다리~중랑천 길~응봉공원~용비교~서울숲~뚝섬역",
//     phoneNumber: "02-2286-5656",
//     institutionNm: "서울특별시 성동구청",
//     referenceDate: "2023-08-08",
//   },
//   {
//     stretNm: "청계천길",
//     stretIntrcn:
//       "지하철 1호선과 2호선이 지나는 시청역 4번 출구를 나와 400m만 걸으면 바로 청계천의 들머리가 되는 청계광장이 나온다. 이어 물가로 내려가는 통로를 이용해 물길을 따라 휘적휘적 걸으면 2시간 정도 되는 청계천 산책로가 시작된다. 이후 중랑천과 만나는 합수머리에서 중랑천 둔치로 길을 갈아탄 뒤 조금만 더 걸으면 중랑천변 바로 옆으로 자리한 응봉역까지 갈 수 있다. 이후 더 걷고 싶다면 응봉역 바로 옆에 붙은 전망 좋은 응봉공원과 서울숲을 연결할 수 있다.",
//     stretLt: 10.4,
//     reqreTime: "3시간",
//     beginSpotNm: "지하철 1호선, 2호선 시청역 4번 출입구",
//     beginRdnmadr: "서울특별시 중구 서소문로 지하127",
//     beginLnmadr: null,
//     endSpotNm: "중앙선 응봉역 2번 출구",
//     endRdnmadr: "서울특별시 성동구 고산자로 123",
//     endLatitude: null,
//     coursInfo: "시청역~청계천길~중랑천 합수머리~응봉역",
//     phoneNumber: "02-2286-5656",
//     institutionNm: "서울특별시 성동구청",
//     referenceDate: "2023-08-08",
//   },
//   {
//     stretNm: "서울숲길",
//     stretIntrcn:
//       "응봉공원과 서울숲을 돌아볼 수 있는 코스이다. 응봉공원 전망대에서는 서울숲과 함께 한강 일대가 조망되어 야경 촬영지로도 명성이 높다. 이후 걷게 되는 서울숲은 115만㎡에 5개의 테마공원과 다양한 볼거리를 갖추고 내방객을 기다린다.",
//     stretLt: 4.2,
//     reqreTime: "1시간",
//     beginSpotNm: "중앙선 응봉역 1번 출입구",
//     beginRdnmadr: "서울특별시 성동구 고산자로 123",
//     beginLnmadr: null,
//     endSpotNm: "지하철 2호선 뚝섬역 8번 출입구",
//     endRdnmadr: "서울특별시 성동구 아차산로 18",
//     endLatitude: null,
//     coursInfo: "응봉역~ 응봉공원~용비교~서울숲~뚝섬역",
//     phoneNumber: "02-2286-5656",
//     institutionNm: "서울특별시 성동구청",
//     referenceDate: "2023-08-08",
//   },
//   {
//     stretNm: "한강수변길",
//     stretIntrcn:
//       "5개의 테마공원으로 이루어진 서울숲의 금쪽같은 산책로를 걸어 서울숲의 얼굴마담이라고 할 수 있는 생태숲 보행가교를 이용해 한강시민공원 뚝섬지구로 나간다. 이후 뚝섬지구에 나 있는 산책로를 따라 다양한 익스트림 탈 것 볼 것이 산재한 뚝섬유원지역 부근까지 간다. 전체적인 코스에 경사로가 없으므로 입문자 걷기 코스로도 적합하다. 다만 뚝섬지구 산책로를 걸을 때는 도보전용 산책로를 이용해야 좀 더 안전한 걷기를 할 수 있다.",
//     stretLt: 6.6,
//     reqreTime: "2시간",
//     beginSpotNm: "지하철 2호선 뚝섬역 8번 출입구",
//     beginRdnmadr: "서울특별시 성동구 아차산로 18",
//     beginLnmadr: null,
//     endSpotNm: "지하철 7호선 뚝섬유원지역 3번 출입구",
//     endRdnmadr: "서울특별시 광진구 능동로 10",
//     endLatitude: null,
//     coursInfo:
//       "뚝섬역~서울숲~생태숲 보행가교~한강시민공원 뚝섬지구~ 뚝섬유원지역",
//     phoneNumber: "02-2286-5656",
//     institutionNm: "서울특별시 성동구청",
//     referenceDate: "2023-08-08",
//   },
// ];

export const roadData = [
  {
    stretNm: "서울둘레길",
    stretIntrcn: "안양천 수변길",
    stretLt: 6.21,
    reqreTime: "1시간 50분",
    coursInfo: "신정교~오목교~목동교~양평교~안양천합수부",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14124206.9397005,
        lat: 4511372.5743602,
      },
      {
        lon: 14125136.4129208,
        lat: 4513356.8409606,
      },
      {
        lon: 14124964.8139258,
        lat: 4514574.9011967,
      },
      {
        lon: 14123994.5087162,
        lat: 4516599.1476553,
      },
    ],
    institutionNm: "서울특별시 영등포구",
  },
  {
    stretNm: "샤로수길",
    stretIntrcn: "강감찬10리길 4코스",
    stretLt: 2.28,
    reqreTime: "35분",
    coursInfo:
      "서울대입구역(2번출구)~샤로수길~낙성대재래시장~재즈골목~행운동고백길~한국커피교육원~중고서점 흙~낙성대역(4번출구)",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14132325.1362056,
        lat: 4506341.742571,
      },
      {
        lon: 14133431.4738329,
        lat: 4505837.6475787,
      },
      {
        lon: 14132658.3599693,
        lat: 4506019.0634345,
      },
      {
        lon: 14133749.6472015,
        lat: 4505584.4729939,
      },
      {
        lon: 14133250.1232504,
        lat: 4505983.0689773,
      },
      {
        lon: 14133350.1994727,
        lat: 4505862.5740126,
      },
      {
        lon: 14133431.4738329,
        lat: 4505837.6475787,
      },
    ],
    institutionNm: "서울특별시 관악구",
  },
  {
    stretNm: "강감찬10리길",
    stretIntrcn: "강감찬10리길 1코스",
    stretLt: 2.6,
    reqreTime: "46분",
    coursInfo:
      "낙성대역(4번출구)~강감찬생가터~벽화길~낙성대공원~전통야외소극장~서울특별시 과학전시관~서울영어마을 관악캠프~덕수공원~낙성대역(4번출구)",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14133431.4738329,
        lat: 4505837.6475787,
      },
      {
        lon: 14133218.6309665,
        lat: 4505515.5450313,
      },
      {
        lon: 14133190.2778922,
        lat: 4504999.090671,
      },
      {
        lon: 14133054.3679259,
        lat: 4504640.6313186,
      },
      {
        lon: 14132986.0400224,
        lat: 4505084.9028079,
      },
      {
        lon: 14132900.2906187,
        lat: 4505340.1833406,
      },
      {
        lon: 14133431.4738329,
        lat: 4505837.6475787,
      },
    ],
    institutionNm: "서울특별시 관악구",
  },
  {
    stretNm: "당신만을 위한 길",
    stretIntrcn: "강감찬10리길 2코스",
    stretLt: 3,
    reqreTime: "1시간",
    coursInfo:
      "서울대입구역(2번출구)~샤로수길~백설상상 어린이공원~관악구민운동장~덕수공원~낙성대공원~봉천로~낙성대역(4번출구)",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14132325.1362056,
        lat: 4506341.742571,
      },
      {
        lon: 14132658.3599693,
        lat: 4506019.0634345,
      },
      {
        lon: 14132419.6464533,
        lat: 4505683.9241904,
      },
      {
        lon: 14132795.2384152,
        lat: 4505500.8309197,
      },
      {
        lon: 14132900.2906187,
        lat: 4505340.1833406,
      },
      {
        lon: 14133190.2778922,
        lat: 4504999.090671,
      },
      {
        lon: 14133431.4738329,
        lat: 4505837.6475787,
      },
    ],
    institutionNm: "서울특별시 관악구",
  },
  {
    stretNm: "역사문화의 길",
    stretIntrcn: "강감찬10리길 5코스",
    stretLt: 5.5,
    reqreTime: "1시간30분",
    coursInfo:
      "서울대입구역(2번출구)~관악구청 갤러리관악~서울대학교 미술관~서울대학교 박물관~4.19공원~규장각~민주화열사추모비~서울대입구역(2번출구)",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14132325.1362056,
        lat: 4506341.742571,
      },
      {
        lon: 14132174.632254,
        lat: 4505966.642865,
      },
      {
        lon: 14131974.5466013,
        lat: 4504324.4421082,
      },
      {
        lon: 14132259.7248728,
        lat: 4504039.2993429,
      },
      {
        lon: 14132046.2140894,
        lat: 4503723.0861916,
      },
      {
        lon: 14132325.1362056,
        lat: 4506341.742571,
      },
    ],
    institutionNm: "서울특별시 관악구",
  },
  {
    stretNm: "숨길 + 쉼길 + 순례길",
    stretIntrcn: "삼성산 성지를 중심으로 하는 관광코스",
    stretLt: 4,
    reqreTime: "1시간40분",
    coursInfo: "삼성산 성지~도란도란 숲길~관악정~약수사",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14129771.5116146,
        lat: 4502950.3976895,
      },
      {
        lon: 14129712.5011525,
        lat: 4502682.3863289,
      },
      {
        lon: 14130201.861634,
        lat: 4503227.5874264,
      },
      {
        lon: 14130294.8022769,
        lat: 4503296.6686359,
      },
    ],
    institutionNm: "서울특별시 관악구",
  },
  {
    stretNm: "송정둑길",
    stretIntrcn:
      "무료로 개방되는 어린이대공원을 후문으로 들어가 한 바퀴 돈 후 정문으로 나가게 된다. 이름 그대로 아이들과 함께 가도 아주 좋을만한 코스이다. 이후 군자교 부근에서 시작되는 송정동 둑길을 지난다. 옆으로 동부간선도로를 달리는 자동차 소리가 들리기는 하지만 높이 솟은 가로수들이 시원한 그늘을 드리우고 있어 한여름에도 걷기가 나쁘지 않다. 또한 중반 무렵 화장실 왼쪽으로 시작되는 은행나무길은 울창한 숲 속에 들어와 있는 착각마저 불러일으킨다. 이후 조선시대에 가장 긴 다리였다는 살곶이다리를 건너 중랑천길을 잠시 걸어 응봉역으로 진입한다.",
    stretLt: 6.3,
    reqreTime: "1시간40분",
    coursInfo: "아차산역~어린이대공원~송정동 둑길~살곶이다리~응봉역",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14147550.5812601,
        lat: 4516334.6482555,
      },
      {
        lon: 14146583.4375241,
        lat: 4516035.2638386,
      },
      {
        lon: 14145323.1673049,
        lat: 4516684.4510853,
      },
      {
        lon: 14142750.1953864,
        lat: 4516502.1904447,
      },
      {
        lon: 14141414.3948928,
        lat: 4516043.6322201,
      },
    ],
    institutionNm: "서울특별시 성동구",
  },
  {
    stretNm: "중랑천길",
    stretIntrcn:
      "무료로 개방되는 어린이대공원을 후문으로 입장해 산책하듯 거닐다 정문으로 나가게 된다. 이름 그대로 아이들과 함께 가도 좋을만한 코스이다. 이후 군자교 부근에서 시작되는 송정동 둑길을 지나 조선시대에 가장 긴 다리였다는 살곶이다리를 건너 응봉역을 거쳐 응봉공원으로 오른다. 응봉공원 전망대에서는 서울숲과 함께 한강 일대가 조망되어 야경 촬영지로도 명성이 높다. 이후 걷게 되는 서울숲은 115만㎡에 5개의 테마공원과 다양한 볼거리를 갖추고 내방객을 기다린다.",
    stretLt: 12.8,
    reqreTime: "3시간",
    coursInfo:
      "아차산역~어린이대공원~송정동 둑길~살곶이다리~중랑천 길~응봉공원~용비교~서울숲~뚝섬역",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14147550.5812601,
        lat: 4516334.6482555,
      },
      {
        lon: 14145323.1673049,
        lat: 4516684.4510853,
      },
      {
        lon: 14142750.1953864,
        lat: 4516502.1904447,
      },
      {
        lon: 14141819.5533114,
        lat: 4516292.9877873,
      },
      {
        lon: 14140999.1175323,
        lat: 4515476.942222,
      },
      {
        lon: 14141868.5116235,
        lat: 4514921.2897958,
      },
      {
        lon: 14142852.3978109,
        lat: 4515657.1772311,
      },
    ],
    institutionNm: "서울특별시 성동구",
  },
  {
    stretNm: "청계천길",
    stretIntrcn:
      "지하철 1호선과 2호선이 지나는 시청역 4번 출구를 나와 400m만 걸으면 바로 청계천의 들머리가 되는 청계광장이 나온다. 이어 물가로 내려가는 통로를 이용해 물길을 따라 휘적휘적 걸으면 2시간 정도 되는 청계천 산책로가 시작된다. 이후 중랑천과 만나는 합수머리에서 중랑천 둔치로 길을 갈아탄 뒤 조금만 더 걸으면 중랑천변 바로 옆으로 자리한 응봉역까지 갈 수 있다. 이후 더 걷고 싶다면 응봉역 바로 옆에 붙은 전망 좋은 응봉공원과 서울숲을 연결할 수 있다.",
    stretLt: 10.4,
    reqreTime: "3시간",
    coursInfo: "시청역~청계천길~중랑천 합수머리~응봉역",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14135030.611714,
        lat: 4518207.1816553,
      },
      {
        lon: 14135094.230803,
        lat: 4518740.9305675,
      },
      {
        lon: 14138202.8943751,
        lat: 4518809.0748702,
      },
      {
        lon: 14141463.531316,
        lat: 4519030.3924259,
      },
      {
        lon: 14142784.4261298,
        lat: 4518348.3089325,
      },
      {
        lon: 14143162.3891969,
        lat: 4516689.2954985,
      },
      {
        lon: 14141414.3948928,
        lat: 4516043.6322201,
      },
    ],
    institutionNm: "서울특별시 성동구",
  },
  {
    stretNm: "서울숲길",
    stretIntrcn:
      "응봉공원과 서울숲을 돌아볼 수 있는 코스이다. 응봉공원 전망대에서는 서울숲과 함께 한강 일대가 조망되어 야경 촬영지로도 명성이 높다. 이후 걷게 되는 서울숲은 115만㎡에 5개의 테마공원과 다양한 볼거리를 갖추고 내방객을 기다린다.",
    stretLt: 3.6,
    reqreTime: "1시간",
    coursInfo: "응봉역~응봉공원~용비교~서울숲~뚝섬역",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14141414.3948928,
        lat: 4516043.6322201,
      },
      {
        lon: 14141127.6804123,
        lat: 4515839.4512366,
      },
      {
        lon: 14140999.1175323,
        lat: 4515476.942222,
      },
      {
        lon: 14141868.5116235,
        lat: 4514921.2897958,
      },
      {
        lon: 14142852.3978109,
        lat: 4515657.1772311,
      },
    ],
    institutionNm: "서울특별시 성동구",
  },
  {
    stretNm: "한강수변길",
    stretIntrcn:
      "5개의 테마공원으로 이루어진 서울숲의 금쪽같은 산책로를 걸어 서울숲의 얼굴마담이라고 할 수 있는 생태숲 보행가교를 이용해 한강시민공원 뚝섬지구로 나간다. 이후 뚝섬지구에 나 있는 산책로를 따라 다양한 익스트림 탈 것 볼 것이 산재한 뚝섬유원지역 부근까지 간다. 전체적인 코스에 경사로가 없으므로 입문자 걷기 코스로도 적합하다. 다만 뚝섬지구 산책로를 걸을 때는 도보전용 산책로를 이용해야 좀 더 안전한 걷기를 할 수 있다.",
    stretLt: 6.6,
    reqreTime: "2시간",
    coursInfo:
      "뚝섬역~서울숲~생태숲 보행가교~한강시민공원 뚝섬지구~뚝섬유원지역",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14142852.3978109,
        lat: 4515657.1772311,
      },
      {
        lon: 14141868.5116235,
        lat: 4514921.2897958,
      },
      {
        lon: 14141229.9273646,
        lat: 4515165.7889537,
      },
      {
        lon: 14143930.7719821,
        lat: 4513691.0887025,
      },
      {
        lon: 14145443.0472646,
        lat: 4513164.728088,
      },
      {
        lon: 14145000.9641708,
        lat: 4513459.4120927,
      },
    ],
    institutionNm: "서울특별시 성동구",
  },
  {
    stretNm: "북한산 소나무의 짙은 솔향기를 맡으며",
    stretIntrcn: "북한산 소나무의 짙은 솔향기를 맡으며 힐링하는 길",
    stretLt: 6,
    reqreTime: "2시간",
    coursInfo:
      "우이령 숲속문화마을~우이동 만남의광장~봉황각~도선사~의암 손병희 선생 묘역~몽양 여운형 선생 묘역~박을복 자수박물관~솔밭근린공원",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14138417.2400546,
        lat: 4531750.8208775,
      },
      {
        lon: 14138267.3372283,
        lat: 4531554.6995799,
      },
      {
        lon: 14136417.9976597,
        lat: 4530823.0493303,
      },
      {
        lon: 14138435.1958884,
        lat: 4531532.6507535,
      },
      {
        lon: 14138926.738232,
        lat: 4531106.5888274,
      },
      {
        lon: 14138520.7226532,
        lat: 4530635.8598323,
      },
      {
        lon: 14138869.3085067,
        lat: 4530427.5832964,
      },
    ],
    institutionNm: "서울특별시 강북구",
  },
  {
    stretNm: "너랑나랑우리랑 스탬프 투어",
    stretIntrcn:
      "봉황각, 국립4.19민주묘지, 근현대사기념관 등 역사문화를 체험하는 힐링투어 길",
    stretLt: 4.8,
    reqreTime: "1시간40분",
    coursInfo:
      "우이동 만남의광장~소나무쉼터~솔밭공원~4.19전망대~근현대사기념관",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14138417.2400546,
        lat: 4531750.8208775,
      },
      {
        lon: 14138869.3085067,
        lat: 4530427.5832964,
      },
      {
        lon: 14138448.2091369,
        lat: 4529944.6671147,
      },
      {
        lon: 14137914.8774565,
        lat: 4529188.3724848,
      },
    ],
    institutionNm: "서울특별시 강북구",
  },
  {
    stretNm: "초대길",
    stretIntrcn:
      "초대검사 이준, 초대 부통령 이시영, 초대 대법원장 김병로 등 대한민국 초대 직위를 역임하신 분들의 묘역",
    stretLt: 1.5,
    reqreTime: "30분",
    coursInfo:
      "근현대사기념관~성재 이시영 선생 묘역~광복군 합동 묘역~가인 김병로 선생 묘역~일성 이준열사 묘역~해공 신익희 선생 묘역~근현대사기념관",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14137914.8774565,
        lat: 4529188.3724848,
      },
      {
        lon: 14137618.5115762,
        lat: 4529221.9163102,
      },
      {
        lon: 14137585.4608194,
        lat: 4529169.9276443,
      },
      {
        lon: 14137475.7443293,
        lat: 4528893.9337393,
      },
      {
        lon: 14137556.0724738,
        lat: 4528826.1453986,
      },
      {
        lon: 14137914.8774565,
        lat: 4529188.3724848,
      },
    ],
    institutionNm: "서울특별시 강북구",
  },
  {
    stretNm: "역사힐링길",
    stretIntrcn:
      "서울시 미래유산 1호인 윤극영가옥, 4.19혁명 희생자를 위한 국립4.19민주묘지, 전통사찰인 본원정사, 화계사 등 근현대사 역사를 따라가는 길",
    stretLt: 4.4,
    reqreTime: "1시간30분",
    coursInfo:
      "윤극영가옥기념관~국립4.19민주묘지~근현대사기념관~본원정사~화계사~구름전망대~빨래골터",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14138789.3143206,
        lat: 4529607.2000356,
      },
      {
        lon: 14138448.2091369,
        lat: 4529944.6671147,
      },
      {
        lon: 14137914.8774565,
        lat: 4529188.3724848,
      },
      {
        lon: 14138163.5763309,
        lat: 4528311.9833313,
      },
      {
        lon: 14138400.542131,
        lat: 4527725.2017581,
      },
      {
        lon: 14138622.9139458,
        lat: 4526886.4353151,
      },
    ],
    institutionNm: "서울특별시 강북구",
  },
  {
    stretNm: "마을안관광코스 1코스",
    stretIntrcn: "세종대학교 박물관에서 유니버셜 아트센터까지",
    stretLt: 1.8,
    reqreTime: "30분",
    coursInfo: "세종대학교~어린이대공원~상상나라~유니버셜아트센터",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14145800.0600035,
        lat: 4516255.9751209,
      },
      {
        lon: 14146583.4375241,
        lat: 4516035.2638386,
      },
      {
        lon: 14146210.1387437,
        lat: 4516139.6865555,
      },
      {
        lon: 14147372.6927138,
        lat: 4516163.8231365,
      },
    ],
    institutionNm: "서울특별시 광진구",
  },
  {
    stretNm: "마을안관광코스 2코스",
    stretIntrcn: "문화프로그램을 진행하는 함께누리에서 워커힐 호텔까지간",
    stretLt: 2.2,
    reqreTime: "40분",
    coursInfo: "함께누리~영화사~아차산생태공원~워커힐호텔",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14147554.9227202,
        lat: 4516574.2799057,
      },
      {
        lon: 14148521.4875949,
        lat: 4516662.4195649,
      },
      {
        lon: 14148679.4499523,
        lat: 4516389.9992252,
      },
      {
        lon: 14149906.613755,
        lat: 4516775.3017581,
      },
    ],
    institutionNm: "서울특별시 광진구",
  },
  {
    stretNm: "마을안관광코스 3코스",
    stretIntrcn: "워커힐 호텔에서 광나루 리버뷰8번가까지",
    stretLt: 4,
    reqreTime: "1시간",
    coursInfo: "워커힐호텔~워커힐길~서울둘레길~광나루리버뷰8번가",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14149906.613755,
        lat: 4516775.3017581,
      },
      {
        lon: 14149275.7773326,
        lat: 4516306.369106,
      },
      {
        lon: 14148811.341285,
        lat: 4516172.7532643,
      },
      {
        lon: 14150217.261926,
        lat: 4515295.7836825,
      },
    ],
    institutionNm: "서울특별시 광진구",
  },
  {
    stretNm: "마을안관광코스 4코스",
    stretIntrcn: "자양2동주민센터하늘공원에서 자양유수지체육공원까지",
    stretLt: 4.5,
    reqreTime: "1시간",
    coursInfo:
      "자양2동주민센터하늘공원~낙천정나들목~자양뚝방길~자양유수지체육공원",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14146974.9259093,
        lat: 4513076.7442083,
      },
      {
        lon: 14146904.5497272,
        lat: 4512914.5767513,
      },
      {
        lon: 14144669.933401,
        lat: 4513608.4768172,
      },
      {
        lon: 14146759.3334515,
        lat: 4513131.8814351,
      },
    ],
    institutionNm: "서울특별시 광진구",
  },
  {
    stretNm: "마을안관광코스 5코스",
    stretIntrcn: "화양동 느티나무공원에서 양꼬치거리까지",
    stretLt: 1.9,
    reqreTime: "30분",
    coursInfo: "화양동느티나무공원~그린나래~커먼그라운드~양꼬치거리",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14145558.2629375,
        lat: 4515554.219515,
      },
      {
        lon: 14145100.8845457,
        lat: 4515590.4574829,
      },
      {
        lon: 14144936.5769773,
        lat: 4514802.9803674,
      },
      {
        lon: 14144835.3319004,
        lat: 4514560.9605345,
      },
    ],
    institutionNm: "서울특별시 광진구",
  },
  {
    stretNm: "마을안관광코스 6코스",
    stretIntrcn: "어린이대공원에서 청춘뜨락까지",
    stretLt: 2,
    reqreTime: "40분",
    coursInfo: "어린이대공원~근화원~일감호~청춘뜨락",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14146583.4375241,
        lat: 4516035.2638386,
      },
      {
        lon: 14146504.6678524,
        lat: 4515603.9502259,
      },
      {
        lon: 14146052.3099696,
        lat: 4514723.8414143,
      },
      {
        lon: 14145403.7848802,
        lat: 4514695.6087341,
      },
    ],
    institutionNm: "서울특별시 광진구",
  },
  {
    stretNm: "마을안관광코스 7코스",
    stretIntrcn: "중곡문화체육센터에서 중랑천공원까지",
    stretLt: 2,
    reqreTime: "50분",
    coursInfo:
      "중곡동문화체육센터~서울병원광장~중곡3동주민센터북카페~중랑천공원",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14147027.7470077,
        lat: 4518557.2718037,
      },
      {
        lon: 14147110.0789031,
        lat: 4518168.4348337,
      },
      {
        lon: 14146500.2150728,
        lat: 4518690.7074331,
      },
      {
        lon: 14145967.0058438,
        lat: 4518442.3058954,
      },
    ],
    institutionNm: "서울특별시 광진구",
  },
  {
    stretNm: "마을안관광코스 8코스",
    stretIntrcn: "뻥튀기골공원에서 신성시장까지",
    stretLt: 1.8,
    reqreTime: "30분",
    coursInfo: "뻥튀기골공원~용암사~서울병원광장~신성시장",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14147529.2413137,
        lat: 4518636.6223307,
      },
      {
        lon: 14147612.2967858,
        lat: 4518456.6028331,
      },
      {
        lon: 14147110.0789031,
        lat: 4518168.4348337,
      },
      {
        lon: 14147375.8987151,
        lat: 4517187.5799468,
      },
    ],
    institutionNm: "서울특별시 광진구",
  },
  {
    stretNm: "마을안관광코스 9코스",
    stretIntrcn: "테크노마트 하늘공원에서 자양유수지체육시설까지",
    stretLt: 2.6,
    reqreTime: "40분",
    coursInfo: "테크노마트하늘공원~미가로~자양골목시장~자양유수지체육공원",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14148199.3735163,
        lat: 4514090.9619018,
      },
      {
        lon: 14147040.2927143,
        lat: 4514348.2308593,
      },
      {
        lon: 14146497.3764258,
        lat: 4513799.9942026,
      },
      {
        lon: 14146759.3334515,
        lat: 4513131.8814351,
      },
    ],
    institutionNm: "서울특별시 광진구",
  },
  {
    stretNm: "마을안관광코스 10코스",
    stretIntrcn: "청담대교걷고싶은거리에서 스타시티쇼핑몰까지",
    stretLt: 1,
    reqreTime: "20분",
    coursInfo: "아트큐브~나루아트센터~더클래식500펜타즈~스타시티쇼핑몰",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14145088.1050682,
        lat: 4513628.9998789,
      },
      {
        lon: 14145434.2975526,
        lat: 4514315.0295281,
      },
      {
        lon: 14145475.8865143,
        lat: 4514453.2123365,
      },
      {
        lon: 14145701.2305596,
        lat: 4514443.1044191,
      },
    ],
    institutionNm: "서울특별시 광진구",
  },
  {
    stretNm: "마을안관광코스 11코스",
    stretIntrcn: "어린이대공원 상상나라에서 능마루 맛의거리까지",
    stretLt: 2,
    reqreTime: "40분",
    coursInfo: "상상나라~서울시민안전체험관~치성당~능마루",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14146583.4375241,
        lat: 4516035.2778795,
      },
      {
        lon: 14146167.5701704,
        lat: 4516235.2924549,
      },
      {
        lon: 14145701.2305596,
        lat: 4514443.1044191,
      },
    ],
    institutionNm: "서울특별시 광진구",
  },
  {
    stretNm: "마을안관광코스 12코스",
    stretIntrcn: "어린이대공원 놀이동산에서 작은도서관 놀자까지",
    stretLt: 1.4,
    reqreTime: "30분",
    coursInfo:
      "어린이대공원놀이동산~무지개분수~유니버셜아트센터~작은도서관놀자",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14146937.3778451,
        lat: 4516208.4176873,
      },
      {
        lon: 14147084.8539065,
        lat: 4516149.2625513,
      },
      {
        lon: 14147372.6927138,
        lat: 4516163.8231365,
      },
      {
        lon: 14148092.76284,
        lat: 4516590.2452469,
      },
    ],
    institutionNm: "서울특별시 광진구",
  },
  {
    stretNm: "마을안관광코스 13코스",
    stretIntrcn: "예스24라이브홀에서 상부암 석불입상까지",
    stretLt: 1.5,
    reqreTime: "30분",
    coursInfo: "에스24라이브홀~광나루리버뷰8번가~광진정보도서관~상부암석불입상",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14149596.6780287,
        lat: 4515450.7573675,
      },
      {
        lon: 14149805.9586714,
        lat: 4515624.3929582,
      },
      {
        lon: 14149857.3771442,
        lat: 4516149.0940586,
      },
      {
        lon: 14149888.4464141,
        lat: 4516303.3361928,
      },
    ],
    institutionNm: "서울특별시 광진구",
  },
  {
    stretNm: "마을안관광코스 14코스",
    stretIntrcn: "뚝섬전망문화콤플렉스에서 뚝섬한강시민공원 장미원까지",
    stretLt: 1.8,
    reqreTime: "30분",
    coursInfo: "뚝섬전망문화콤플렉스~음악분수~X게임장~장미원",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14144280.5600861,
        lat: 4513531.7613512,
      },
      {
        lon: 14145343.5499037,
        lat: 4513200.3541897,
      },
      {
        lon: 14145547.4204191,
        lat: 4513206.7129983,
      },
      {
        lon: 14145452.9546992,
        lat: 4513050.2705819,
      },
    ],
    institutionNm: "서울특별시 광진구",
  },
  {
    stretNm: "해방촌거리",
    stretIntrcn:
      "이 마을의 법정 동명은 ‘용산동 2가’이지만, 마을이 생기면서부터 지금까지 사람들은 여전히 해방촌이라는 명칭을 사용하고 있다.",
    stretLt: 1.3,
    reqreTime: "21분",
    coursInfo: "보성여중고~천주교해방촌성당~108계단~소월길90계단",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14135822.7500785,
        lat: 4515083.0250335,
      },
      {
        lon: 14135790.0555441,
        lat: 4515131.1950998,
      },
      {
        lon: 14135607.5917667,
        lat: 4515525.4370263,
      },
      {
        lon: 14135336.7069178,
        lat: 4516327.697812,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "성지순례길",
    stretIntrcn:
      "천주교 신부 등 순교자들의 발자취가 남겨져있는 성당 및 성지순례 코스길이다.",
    stretLt: 5,
    reqreTime: "1시간24분",
    coursInfo: "용산신학교성당~당고개순교성지~왜고개순교성지~새남터순교성지",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14132442.6116642,
        lat: 4513818.0610019,
      },
      {
        lon: 14133892.4255804,
        lat: 4514022.9606212,
      },
      {
        lon: 14134449.8356666,
        lat: 4513232.5974602,
      },
      {
        lon: 14132767.6868412,
        lat: 4512530.3286509,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "순국열사 추모길",
    stretIntrcn:
      "유관순열사가 1920년 순국한뒤 이태원 공동묘지에 안장됐다가 일제가 군용기지 조성을 위해 이전하는 과정에서 유해의 행방이 묘연해짐으로 우리나라를 위해 순국하신 유관순열사를 추모하는 기념으로 2015년에 설립",
    stretLt: 5.3,
    reqreTime: "1시간24분",
    coursInfo:
      "서울역사문화관 강우규의사동상~백범광장안중근의사기념관~백범김구기념관~삼의사묘역",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14134413.0445749,
        lat: 4516866.8131946,
      },
      {
        lon: 14135264.4160405,
        lat: 4516753.0313347,
      },
      {
        lon: 14133038.7052735,
        lat: 4515243.0920291,
      },
      {
        lon: 14133288.0609329,
        lat: 4515394.709363,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "남산숲속여행길",
    stretIntrcn:
      "서울 남산은 용산구와 중구의 경계부에 있는 서울의 대표적인 산이다. 남산 숲속 여행길은 남산도서관, 안중근의사기념관을 둘러보고 서울한양도성길을 이용해 남산을 오르는 코스로 남산 봉수대와 팔각정 그리고 남산N서울타워 까지 이어져 있다.",
    stretLt: 1.3,
    reqreTime: "20분",
    coursInfo: "N서울타워~남산봉수대~남산성곽길~남산도서관",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14136267.0484302,
        lat: 4516220.3526592,
      },
      {
        lon: 14136182.8352354,
        lat: 4516331.0536821,
      },
      {
        lon: 14135589.1795229,
        lat: 4516408.4496134,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "건축물 탐방 코스",
    stretIntrcn:
      "다양한 건축가의 건축물과 서울특별시 건축상을 수상한 여러 건축물을 볼 수 있는 코스이다.",
    stretLt: 10,
    reqreTime: "3시간6분",
    coursInfo:
      "용산구청~현대카드 뮤직라이브러리~서우제~용산드래곤시티호텔~아모레퍼시픽 본사~리움미술관",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14136516.6823883,
        lat: 4513596.4184844,
      },
      {
        lon: 14137650.3823464,
        lat: 4514181.4250664,
      },
      {
        lon: 14133430.2047907,
        lat: 4513496.9061842,
      },
      {
        lon: 14134096.4853389,
        lat: 4513081.0254744,
      },
      {
        lon: 14137509.6188503,
        lat: 4514366.705726,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "역사문화전문가 코스",
    stretIntrcn: "조선시대 역사와 문화의 흔적을 보여주는 길",
    stretLt: 8.7,
    reqreTime: "2시간30분",
    coursInfo:
      "유관순열사추모비~효창공원~심원정터~용산신학교성심성당~새남터성당~연복사탑중창비~용산철도병원(현 용사역사박물관)",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14136425.7343643,
        lat: 4514064.2751368,
      },
      {
        lon: 14133114.7587496,
        lat: 4515380.318328,
      },
      {
        lon: 14132276.2335533,
        lat: 4513708.0042157,
      },
      {
        lon: 14132442.6116642,
        lat: 4513818.0610019,
      },
      {
        lon: 14132767.6868412,
        lat: 4512530.3286509,
      },
      {
        lon: 14133475.3782401,
        lat: 4512951.6758623,
      },
      {
        lon: 14133770.964884,
        lat: 4512637.9026614,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "마을관광길",
    stretIntrcn:
      "용산 마을관광길은 용산역주변과 원효로동 일대를 둘러보는역사 문화길로 용산전자상가와 과학동아천문대, 신계역사공원, 열정도 문화거리 등을 둘러볼 수 있다.",
    stretLt: 1.7,
    reqreTime: "35분",
    coursInfo: "용산역~용산전자상가~과학동아천문대~신계역사공원~열정도",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14133687.7980924,
        lat: 4513248.8103743,
      },
      {
        lon: 14133381.9032636,
        lat: 4513621.9108551,
      },
      {
        lon: 14133497.041013,
        lat: 4513757.8384589,
      },
      {
        lon: 14133822.8397667,
        lat: 4514016.6153549,
      },
      {
        lon: 14134055.6978775,
        lat: 4514457.0870407,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "이태원 젊음의길",
    stretIntrcn:
      "이태원으로 대표되는 젊음의 거리로써, 대형 브랜드 매장이 아닌 작은 개인상점들로 구성되어 각각의 스타일을 갖고있는 이태원 로데오 패션거리, 미군부대에서 근무하던 군인들이 사용하던 가구들을 팔기 위해 내놓은 거리인 엔틱가구거리, 한남동의 이색적인 동네인 한남동 가로수길, 다양한 장르의 음악으로 1만장 이상의 LP레코드를 소장하고 있는 세련되고 깔끔한 건물이 모여있는 현대카드 뮤직 라이브러리, 수천개의 블록이 쌓여진 구조로 마치 살아움직이는 듯한 느낌의 외관이 있는 스페이스 신선이 존재한다.",
    stretLt: 0.5,
    reqreTime: "8분",
    coursInfo: "한남동 가로수길~현대카드 뮤직 라이브러리",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14137813.0312544,
        lat: 4514656.3273892,
      },
      {
        lon: 14137650.3823464,
        lat: 4514181.4250664,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "로데오 패션거리",
    stretIntrcn:
      "패션 피플이라면 한번은 방문해야할 핫플레이스 빈티지부터 빅사이즈까지 다양한 옷이 패피를 유혹함. 오래된 건물 위에 리모델링한 예쁜 샵들도 포토스팟이다.",
    stretLt: 0.7,
    reqreTime: "10분",
    coursInfo: "녹사평대로32길~보광로59길~용산구청 뒷골목",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14136336.8568828,
        lat: 4513760.0564392,
      },
      {
        lon: 14136863.8433523,
        lat: 4513797.0883567,
      },
      {
        lon: 14136876.3333991,
        lat: 4513786.2370648,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "앤틱가구거리",
    stretIntrcn:
      "유럽의 거리를 걷는 듯한 독특한 분위기의 거리. 세월의 흔적이 느껴지는 고풍스러운 특색이 묻어나는 예쁜 물건이 가득, 아기자기한 색색의 취향저격 아이템들을 발견할 수 있다.",
    stretLt: 0.6,
    reqreTime: "10분",
    coursInfo: "이태원역 4번출구 보광로~녹사평대로 26길",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14136877.3018787,
        lat: 4513855.7809855,
      },
      {
        lon: 14137004.6402442,
        lat: 4513474.2917985,
      },
      {
        lon: 14136756.2085366,
        lat: 4513490.3225995,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "용문시장 체험코스",
    stretIntrcn:
      "4천여개의 매장이 있는 용산전자상가를 지나 60년 전통을 이어온 용문전통재래시장을 방문한 후, 도심속의 산책길 경의선 부지공원을 지나 김구, 윤봉길, 이봉창, 백정기, 안중근 의사묘가 있는 효창공원을 방문하는 코스이다.",
    stretLt: 2.2,
    reqreTime: "35분",
    coursInfo: "용산전자상가~용문전통시장~경의선 부지공원~효창공원",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14133381.9032636,
        lat: 4513621.9108551,
      },
      {
        lon: 14133134.9743692,
        lat: 4514089.4176895,
      },
      {
        lon: 14132732.4430904,
        lat: 4514711.5290924,
      },
      {
        lon: 14133114.7587496,
        lat: 4515380.318328,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "한글과 벽화 코스",
    stretIntrcn:
      "국립한글박물관에는 세종대왕의 애민정신이 절정 한글의 역사와 창제 원리 등이 소개되고 외국인이 체험할 수 있는 한글배움터 등 다양한 프로그램이 진행되고 있다. 서빙고동벽화골목은 2013년부터 청소년모임에서 시작된 마을벽화조성 프로그램이다. 반포대교를 비롯하여 조명으로 아름답게 꾸며진 한강의 다리들을 산책하며 한강공원을 구경할 수 있다.",
    stretLt: 2.4,
    reqreTime: "40분",
    coursInfo: "국립한글박물관~서빙고동 벽화골목~한강공원",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14135451.2546738,
        lat: 4512004.0123472,
      },
      {
        lon: 14136981.2074914,
        lat: 4511902.3396983,
      },
      {
        lon: 14136965.2665403,
        lat: 4512219.6138406,
      },
      {
        lon: 14137353.7159034,
        lat: 4512286.9858314,
      },
      {
        lon: 14136981.2074914,
        lat: 4511902.3396983,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "남산가는길(야경)코스",
    stretIntrcn:
      "서울의 상징으로 남산 정상에 위치하며 야경 관만의 최적의 장소로 꼽히는 서울 N타워 야경을 지나 서울의 중심인 남산공원을 방문한 후, 유관순열사 추모비를 지나 예술작품과 건축물의 조화를 비롯한 용산구청을 방문하는 코스이다.",
    stretLt: 4,
    reqreTime: "1시간",
    coursInfo: "서울N타워(야경)~남산공원~유관순열사 추모비~용산구청",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14136267.0484302,
        lat: 4516220.3526592,
      },
      {
        lon: 14135945.5911366,
        lat: 4515583.4232918,
      },
      {
        lon: 14136425.7343643,
        lat: 4514064.2751368,
      },
      {
        lon: 14136516.6823883,
        lat: 4513596.4184844,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "자전거(도로) 관광 코스",
    stretIntrcn:
      "예술작품과 건축물의 조화를 비롯하여 다양한 식물들을 보기위해 용산구청에 많이 방문한다. 구청에서 자전거를 대여해 60여개 대사관이 밀집한 대사관 골목을 둘러본 후 서빙고동으로 내려와 잠수교를 따라 한강공원(잠원)으로 넘어가서 여름철에는 반포대교에서 펼쳐지는 분수쇼를 볼 수 있다. 동작대교를 거쳐 한강대교까지가면 다양한 운동시설이 있다. 한강대교 인근 자전거 대여소에 반납하면 된다.",
    stretLt: 8,
    reqreTime: "2시간",
    coursInfo: "용산구청~대사관골목~반포대교~한강공원",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14136516.6823883,
        lat: 4513596.4184844,
      },
      {
        lon: 14136956.9843702,
        lat: 4514010.6210472,
      },
      {
        lon: 14137118.0525414,
        lat: 4511255.5821623,
      },
      {
        lon: 14132903.9752938,
        lat: 4511510.1850181,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "후암벽화길 코스",
    stretIntrcn:
      "구 서울역사는 1900년에 건설되어 1925년 르네상스풍 건축양식으로 ktx가 들어오면서 구 역사는 이전 모습을 그대로 보존한채 문화관으로 활용되고 있다. 5분을 걸으면 서울스퀘어가 나오는데 옛 대우빌딩으로 현재는 지하의 다양한 음식점과 드라마 촬영지로 유명하다. 10분을 걸으면 후암시장이 나오는데 전통시장에서 느낄 수 있는 후한 인심과 더불어 다양하고 질 좋은 물건을 저렴하게 구매할 수 있다. 3분 걸으면 벽화길이 나오는데 현재의 한강 수변풍경을 자연스레 이어놓아 은은한 옛 정취와 현대의 활기찬 모습을 느낄 수 있다.",
    stretLt: 1.6,
    reqreTime: "26분",
    coursInfo: "구서울역사~서울스퀘어~후암시장~벽화길",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14134413.0445749,
        lat: 4516866.8131946,
      },
      {
        lon: 14134644.533456,
        lat: 4516860.8172542,
      },
      {
        lon: 14134909.2289412,
        lat: 4516115.0305127,
      },
      {
        lon: 14135022.073509,
        lat: 4516393.2989497,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "공연 전시장길 코스",
    stretIntrcn:
      "도심 속 자연을 느낄 수 있는 남산 자락에 세워진 미술관 관람을 시작으로 한남동 인근의 개성 넘치는 가게들과 현대카드 라이브러리의 아날로그 감성을 느껴볼 수 있다.",
    stretLt: 1,
    reqreTime: "17분",
    coursInfo: "리움미술관~69그라운드~현대카드 라이브러리~제일기획 앞",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14137509.6188503,
        lat: 4514366.705726,
      },
      {
        lon: 14137650.3823464,
        lat: 4514181.4250664,
      },
      {
        lon: 14137457.9109468,
        lat: 4513929.2700674,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "기념관&박물관길",
    stretIntrcn:
      "전쟁기념관을 시작으로 국립중앙박물관, 국립 한글 박물관, 용산가족공원까지 관람할 수 있으며, 다양한 체험 프로그램과 전시를 즐길 수 있다.",
    stretLt: 3.8,
    reqreTime: "1시간",
    coursInfo: "삼각지역~전쟁기념관~국립중앙박물관~국립한글박물관~용산가족공원",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14134683.1613193,
        lat: 4514026.6105548,
      },
      {
        lon: 14135031.5022699,
        lat: 4514149.1367584,
      },
      {
        lon: 14135392.1328923,
        lat: 4512411.6672652,
      },
      {
        lon: 14135451.2546738,
        lat: 4512004.0123472,
      },
      {
        lon: 14135720.1914316,
        lat: 4512150.501857,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "대사관길",
    stretIntrcn:
      "50여 곳의 대사관이 모여있는 곳으로 이 길을 따라 걸으며 각 국가별 국기를 비롯한 저마다의 상징들을 만날 수 있다.",
    stretLt: 1.4,
    reqreTime: "26분",
    coursInfo: "용산구 대사관로~독서당로",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14137630.9904911,
        lat: 4513880.4036309,
      },
      {
        lon: 14137882.1272623,
        lat: 4513724.0213367,
      },
      {
        lon: 14138930.3783793,
        lat: 4514339.1478631,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "우사단로 공방길",
    stretIntrcn:
      "요즘 젊은 친구들 사이에 핫하다는 아사단로 예전의 모습을 그대로 유지하면서 작은공방들과 샵들이 예쁘게 들어서기 시작한 곳. 구경할 곳도 많고 가죽공방 등 다양한 수제품 공방들이 많아 뮌가를 취미로 가지시려는 분들은 한번씩 와 보셔서 색다른 경험을 느껴보세요.",
    stretLt: 0.5,
    reqreTime: "8분",
    coursInfo: "우사단로 10길",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14137300.4161313,
        lat: 4513721.1857009,
      },
      {
        lon: 14137820.8347507,
        lat: 4513379.8762129,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "한남동 가로수길",
    stretIntrcn:
      "건축물을 보는 재미가 쏠쏠하고 패션 뿐만 아니라 미술관, 공연장 등 다양한 문화가 들어와 있는 곳. 큰 건물은 큰대로 작은건물은 작은대로 개성을 가지고 조화된 한남동만의 특별함이 이곳에 있다.",
    stretLt: 1.2,
    reqreTime: "18분",
    coursInfo: "이태원역~한강진역",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14136973.1256964,
        lat: 4513879.2103999,
      },
      {
        lon: 14137775.8728084,
        lat: 4514621.4263609,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "한남동 디자이너거리",
    stretIntrcn:
      "친근한 골목길을 연상시키면서 풍부한 문화인프라를 즐길 수 있는곳이며, 개성 뚜렷한 라이프스타일 숍과 패션매장, 맛집이 골목골목 어우러지면서 다양하면서 이색적인 동네가 형성되고 있다.",
    stretLt: 0.5,
    reqreTime: "8분",
    coursInfo: "이태원로54길~이태원로5길",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14137804.7936121,
        lat: 4514293.157419,
      },
      {
        lon: 14137609.4167738,
        lat: 4514056.3435233,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "해방촌길",
    stretIntrcn:
      "광복 이후 해외에서 돌아온 동포들과 6.25 전쟁 때, 북에서 피난을 온 실향민들이 용산구 일대에 모여 거주하면서 형성된 지역이다.",
    stretLt: 0.8,
    reqreTime: "20분",
    coursInfo: "신흥로 초입~용산2가동 주민센터",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14136108.6073989,
        lat: 4514647.9039606,
      },
      {
        lon: 14135969.8476536,
        lat: 4515506.5108281,
      },
    ],
    institutionNm: "서울특별시 용산구",
  },
  {
    stretNm: "시흥동 역사문화길",
    stretIntrcn: "시흥동 일원 관광명소 투어",
    stretLt: 3,
    reqreTime: "1시간",
    coursInfo:
      "은행나무오거리(행궁터)~단군전터~강희맹 살던 곳~순흥안씨양도공파묘역~3층석탑(향나무)~시흥향교터",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14127416.1693006,
        lat: 4502197.0608755,
      },
      {
        lon: 14127383.2521272,
        lat: 4502802.9288916,
      },
      {
        lon: 14127298.0593208,
        lat: 4503230.9250789,
      },
      {
        lon: 14127504.7350875,
        lat: 4503203.0038252,
      },
      {
        lon: 14128223.7254146,
        lat: 4502380.3208298,
      },
      {
        lon: 14127843.903312,
        lat: 4502047.457277,
      },
    ],
    institutionNm: "서울특별시 금천구",
  },
  {
    stretNm: "호암산 역사문화길",
    stretIntrcn: "호암산 일원 역사유적지 투어",
    stretLt: 2.3,
    reqreTime: "1시간 30분",
    coursInfo: "호암산문~호압사~석구상~한우물~칼바위~호암산폭포~호천약수터",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14128851.9903567,
        lat: 4502454.0379758,
      },
      {
        lon: 14129265.7537721,
        lat: 4502376.0860009,
      },
      {
        lon: 14128538.9710806,
        lat: 4501419.5072129,
      },
      {
        lon: 14128982.9911335,
        lat: 4501466.7732248,
      },
    ],
    institutionNm: "서울특별시 금천구",
  },
  {
    stretNm: "패션-IT 문화길",
    stretIntrcn: "가산동 일원 패션 및 IT 관련 투어",
    stretLt: 1.9,
    reqreTime: "40분",
    coursInfo:
      "디지털단지오거리~패션아울렛~수출의 다리~패션 IT 문화존~구로공단노동자생활체험관",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14125313.1214805,
        lat: 4506143.9648789,
      },
      {
        lon: 14125237.1347961,
        lat: 4505898.1332091,
      },
      {
        lon: 14124701.331823,
        lat: 4505783.9093627,
      },
      {
        lon: 14124621.4489564,
        lat: 4506624.1002249,
      },
    ],
    institutionNm: "서울특별시 금천구",
  },
  {
    stretNm: "동작충효길 1코스 고구동산길",
    stretIntrcn: "자연이 살아 숨쉬는 공간",
    stretLt: 3.2,
    reqreTime: "1시간",
    coursInfo:
      "노들나루공원(배수지공원)~노들역~고구동산~중앙대후문~서달산잣나무길~자연학습장~서달산생태다리~동작대전망대~현충원상도출입문",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14132441.353754,
        lat: 4510953.2835177,
      },
      {
        lon: 14132367.02573,
        lat: 4510812.7751588,
      },
      {
        lon: 14132450.3928966,
        lat: 4509731.7293836,
      },
      {
        lon: 14132581.8278194,
        lat: 4509517.4419896,
      },
      {
        lon: 14133587.32112,
        lat: 4508169.683035,
      },
    ],
    institutionNm: "서울특별시 동작구",
  },
  {
    stretNm: "동작충효길 2코스 현충원길",
    stretIntrcn: "충(忠)의 정신을 만나는 공간",
    stretLt: 2.6,
    reqreTime: "50분",
    coursInfo:
      "현충원상도출입문~학수약수터갈림길~현충원사당출입문~극동아파트갈림길~정금마을갈림길~이수갈림길~동작역",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14133587.32112,
        lat: 4508169.683035,
      },
      {
        lon: 14135386.8897443,
        lat: 4509440.3635869,
      },
    ],
    institutionNm: "서울특별시 동작구",
  },
  {
    stretNm: "동작충효길 3코스 한강나들길",
    stretIntrcn: "지극한 효심이 함께 하는 공간",
    stretLt: 4.7,
    reqreTime: "1시간20분",
    coursInfo:
      "동작역~한강수변길~흑석역~효사정~용양봉저정~노들역~노들나루공원(배수지공원)~사육신역사공원~노량진역",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14135386.8897443,
        lat: 4509440.3635869,
      },
      {
        lon: 14133500.4807852,
        lat: 4510319.9021189,
      },
      {
        lon: 14133432.1194859,
        lat: 4510510.1805569,
      },
      {
        lon: 14132655.2541555,
        lat: 4510846.79339,
      },
      {
        lon: 14132367.02573,
        lat: 4510812.7751588,
      },
      {
        lon: 14131857.5164206,
        lat: 4510954.574654,
      },
      {
        lon: 14131080.8848611,
        lat: 4511024.9277883,
      },
    ],
    institutionNm: "서울특별시 동작구",
  },
  {
    stretNm: "동작충효길 4코스 노량진길",
    stretIntrcn: "따뜻한 정을 느낄 수 있는 공간",
    stretLt: 3.4,
    reqreTime: "1시간",
    coursInfo:
      "노량진역~노량진수산시장~현대자동차서비스센터~노량진근린공원~견우와직녀교~무장애산책로~용마산정상~신대방삼거리역",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14131080.8848611,
        lat: 4511024.9277883,
      },
      {
        lon: 14130544.7256657,
        lat: 4511117.9748497,
      },
      {
        lon: 14129705.1206703,
        lat: 4509745.1584495,
      },
      {
        lon: 14129582.2128205,
        lat: 4508994.462195,
      },
    ],
    institutionNm: "서울특별시 동작구",
  },
  {
    stretNm: "동작충효길 5코스 보라매길",
    stretIntrcn: "문화로 교감하는 소통의 공간",
    stretLt: 2.7,
    reqreTime: "50분",
    coursInfo:
      "신대방삼거리역~보라매e편한세상아파트~보라매병원입구~보라매생태다리~기상청입구~농심입구~보라매역",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14129582.2128205,
        lat: 4508994.462195,
      },
      {
        lon: 14129594.2687213,
        lat: 4508407.5284109,
      },
      {
        lon: 14129127.2389297,
        lat: 4508048.840234,
      },
      {
        lon: 14128340.4772965,
        lat: 4508155.9613511,
      },
      {
        lon: 14128563.6506117,
        lat: 4508658.5402701,
      },
      {
        lon: 14128660.4540409,
        lat: 4509025.275338,
      },
    ],
    institutionNm: "서울특별시 동작구",
  },
  {
    stretNm: "동작충효길 6코스 동작마루길",
    stretIntrcn: "우애를 돈돈하게 다지는 공간",
    stretLt: 4.8,
    reqreTime: "1시간20분",
    coursInfo:
      "신대방삼거리역~빙수골마을공원~갑을명가아파트~국사봉정상~상도근린공원관리사무소~봉현배수지~살피재(봉천고개)~숭실대입구역~현충원상도출입문",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14129582.2128205,
        lat: 4508994.462195,
      },
      {
        lon: 14130031.6541326,
        lat: 4508570.945007,
      },
      {
        lon: 14130876.5801997,
        lat: 4508026.840905,
      },
      {
        lon: 14132180.9663331,
        lat: 4508066.3639672,
      },
      {
        lon: 14132453.5655021,
        lat: 4508450.5886542,
      },
      {
        lon: 14133587.32112,
        lat: 4508169.683035,
      },
    ],
    institutionNm: "서울특별시 동작구",
  },
  {
    stretNm: "동작충효길 7코스 까치산길",
    stretIntrcn: "생태 감성을 체험할 수 있는 공간",
    stretLt: 3.6,
    reqreTime: "1시간",
    coursInfo:
      "백운고개생태다리~상도중학교~까치산차없는거리~까치산공원관리사무소~솔밭로생태다리~삼익그린뷰아파트~사당역",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14133507.8056077,
        lat: 4507947.5707468,
      },
      {
        lon: 14133278.420665,
        lat: 4507762.1089794,
      },
      {
        lon: 14133334.7371954,
        lat: 4507254.4804733,
      },
      {
        lon: 14133558.233337,
        lat: 4506388.8203557,
      },
      {
        lon: 14134155.4735371,
        lat: 4505866.0387495,
      },
      {
        lon: 14135500.6805277,
        lat: 4505744.9559912,
      },
    ],
    institutionNm: "서울특별시 동작구",
  },
  {
    stretNm: "한류스타거리",
    stretIntrcn:
      "한류스타거리 상징 및 안내 시설물과 조형물을 설치하고 주변 관광 콘텐츠를 발굴하여 국내외 관광객 유치 및 지역경제 활성화에 기여",
    stretLt: 1,
    reqreTime: "20분",
    coursInfo: "길 관광(거리를 관광화 함)",
    reqCoordType: "EPSG3857",
    coursData: [
      {
        lon: 14142039.6876045,
        lat: 4513023.530322,
      },
      {
        lon: 14142857.4962436,
        lat: 4512497.8910366,
      },
    ],
    institutionNm: "서울특별시 강남구",
  },
  // {
  //   stretNm: "",
  //   stretIntrcn: "",
  //   stretLt: 0,
  //   reqreTime: "",
  //   coursInfo:
  //     "",
  //   reqCoordType: "EPSG3857",
  //   coursData: [
  //     {
  //       lon: ,
  //       lat: ,
  //     },
  //     {
  //       lon: ,
  //       lat: ,
  //     },
  //     {
  //       lon: ,
  //       lat: ,
  //     },
  //   ],
  //   institutionNm: "서울특별시 강남구",
  // },
];
