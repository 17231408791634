import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { roadData } from "../Tools/roadData";
import { FilterAlt } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const regions = [
  "서울특별시 영등포구",
  "서울특별시 관악구",
  "서울특별시 성동구",
  "서울특별시 강북구",
  "서울특별시 광진구",
  "서울특별시 용산구",
  "서울특별시 금천구",
  "서울특별시 동작구",
  "서울특별시 강남구",
];

function getStyles(region, selectedRegions) {
  return {
    fontWeight: selectedRegions.indexOf(region) === -1 ? 400 : 600,
  };
}

export default function RouteList({ selectedRoad, setSelectedRoad }) {
  const { t } = useTranslation();

  const [selectedRegions, setSelectedRegions] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#eceff1",
        height: "100vh",
        minWidth: "min-content",
        boxSizing: "border-box",
        pt: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack direction="row" spacing={1.5} px={3}>
        <FormControl sx={{ width: "100%" }} size="small">
          <InputLabel>지역구 선택</InputLabel>
          <Select
            multiple
            open={openFilter}
            onClose={handleCloseFilter}
            onOpen={handleOpenFilter}
            value={selectedRegions}
            onChange={(event) => {
              setSelectedRegions(event.target.value);
            }}
            input={<OutlinedInput label="지역구 선택" />}
            renderValue={(selected) => (
              <Box
                sx={{
                  width: "min-content",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {regions.map((region, idx) => (
              <MenuItem
                key={idx}
                value={region}
                style={getStyles(region, selectedRegions)}
              >
                {region}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton onClick={handleOpenFilter} sx={{ px: 0.5 }}>
          <FilterAlt />
        </IconButton>
      </Stack>
      <Stack
        direction={"column"}
        mt={2.5}
        px={3}
        pb={2.5}
        spacing={2.5}
        sx={{
          flex: 1,
          overflow: "scroll",
        }}
      >
        {roadData
          .filter((road) => {
            if (selectedRegions.length) {
              return selectedRegions.indexOf(road.institutionNm) !== -1;
            } else {
              return true;
            }
          })
          .map((road, cardIdx) => (
            <Card
              key={cardIdx}
              sx={{
                flex: "none",
                width: 300,
                cursor: "pointer",
                backgroundColor: road === selectedRoad ? "#2962ff" : "#FFF",
                color: road === selectedRoad ? "#FFF" : "none",
              }}
              onClick={() => {
                setSelectedRoad(road);
              }}
            >
              <CardContent>
                <Typography
                  variant="subtitle2"
                  mb={1}
                  fontWeight={600}
                  sx={{ color: road === selectedRoad ? "#FFF" : "#777" }}
                >
                  {road.institutionNm}
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  mb={0.5}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="h5"
                    component="div"
                    fontWeight={500}
                    sx={{ wordBreak: "keep-all" }}
                  >
                    {road.stretNm}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    fontWeight={300}
                  >{`${road.stretLt}km`}</Typography>
                </Stack>

                <Typography
                  variant="subtitle2"
                  mb={1.5}
                  color={road === selectedRoad ? "#FFF" : "#777"}
                  sx={{ wordBreak: "keep-all" }}
                >
                  {road.coursInfo.replaceAll("~", " → ")}
                </Typography>
                <Typography variant="body2">
                  {road.stretIntrcn.split(". ").length === 1
                    ? road.stretIntrcn.split(". ")[0]
                    : road.stretIntrcn.split(". ").length === 2
                    ? road.stretIntrcn.split(". ")[0] +
                      ". " +
                      road.stretIntrcn.split(". ")[1]
                    : road.stretIntrcn.split(". ")[0] +
                      ". " +
                      road.stretIntrcn.split(". ")[1] +
                      ". "}
                </Typography>
              </CardContent>
            </Card>
          ))}
        <Typography
          variant="caption"
          color="GrayText"
          textAlign="center"
          mt={2}
          mb={2.5}
        >
          {t("copyright")}
        </Typography>
      </Stack>
    </Box>
  );
}
