import { Box } from "@mui/system";
import { useCallback, useEffect, useRef, useState } from "react";

import RouteList from "../components/RouteList";
import RouteNavigate from "../components/RouteNavigate";
import MapView from "../components/MapView";
import ToCurrPositionBtn from "../components/ToCurrPositionBtn";
import DeletePolylineBtn from "../components/DeletePolylineBtn";

const { Tmapv2 } = window;

export default function WalkRoadPage() {
  const [selectedRoad, setSelectedRoad] = useState(null);
  const [cours, setCours] = useState(null);
  const [mapInstance, setMapInstance] = useState();
  const [searchOption, setSearchOption] = useState("0");
  const [currLatLng, setCurrLatLng] = useState(null);

  const pathPolyline = useRef();
  const [path, setPath] = useState([]);

  const markers = useRef();
  const currMarker = useRef();

  const watchID = navigator.geolocation.watchPosition((position) => {
    setCurrLatLng(
      new window.Tmapv2.LatLng(
        position.coords.latitude,
        position.coords.longitude
      )
    );
  });

  useEffect(() => {
    pathPolyline.current = new window.Tmapv2.Polyline({
      path: [],
      strokeColor: "blue",
      strokeWeight: 6,
      map: mapInstance,
    });
  }, [mapInstance]);

  useEffect(() => {
    if (pathPolyline.current && path.length) {
      pathPolyline.current.setPath([]);
      pathPolyline.current.setPath(path);

      console.log(path);
      console.log(pathPolyline.current.getPath());
    }
  }, [mapInstance, path]);

  // [selectedRoad] Hook: 보행자 경로 탐색 (to setCourse)
  useEffect(() => {
    if (selectedRoad == null) return;

    const coursNames = selectedRoad.coursInfo
      .split("~")
      .map((data) => data.replace(" ", "").trim());
    const coursData = selectedRoad.coursData;

    // 1. 좌표를 기반으로 보행자 경로를 검색한다.
    const departure = { name: coursNames[0], ...coursData[0] };
    const arrival = {
      name: coursNames[coursNames.length - 1],
      ...coursData[coursData.length - 1],
    };

    const getPedestrianRoutes = async (requestData) => {
      const options = {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          appKey: process.env.REACT_APP_TMAP_API_KEY,
        },
        body: JSON.stringify(requestData),
      };

      return await fetch(
        "https://apis.openapi.sk.com/tmap/routes/pedestrian?version=1&callback=function",
        options
      )
        .then((response) => response.json())
        .then((response) => {
          setCours(response.features);
        })
        .catch((err) => console.error(err));
    };

    const requestData = {
      startX: departure.lon,
      startY: departure.lat,
      endX: arrival.lon,
      endY: arrival.lat,
      reqCoordType: selectedRoad.reqCoordType,
      resCoordType: "WGS84GEO",
      startName: encodeURI(departure.name),
      endName: encodeURI(arrival.name),
      searchOption: searchOption,
    };

    if (coursData.length > 2) {
      // 경유지 있음
      const passList = coursData
        .slice(1, -1)
        .slice(0, 5)
        .map((data) => {
          return `${data.lon},${data.lat}`;
        })
        .join("_");
      getPedestrianRoutes({ ...requestData, passList });
    } else {
      // 경유지 없음
      getPedestrianRoutes(requestData);
    }
  }, [searchOption, selectedRoad]);

  // [course, mapInstance] Hook
  useEffect(() => {
    if (cours == null || mapInstance == null) return;

    pathPolyline.current.setPath([]);
    const drawInfoArr = [];
    cours.forEach((item) => {
      const geometry = item.geometry;
      if (geometry.type === "LineString") {
        geometry.coordinates.forEach((point) => {
          drawInfoArr.push(new window.Tmapv2.LatLng(point[1], point[0]));
        });
      }
    });
    const markerInfoArr = cours
      .filter((poi) => poi.geometry.type === "Point")
      .map(
        (poi) =>
          new window.Tmapv2.LatLng(
            poi.geometry.coordinates[1],
            poi.geometry.coordinates[0]
          )
      );

    setPath(drawInfoArr);
    drawMarker(markerInfoArr);
    resetMapBounds(markerInfoArr);

    function drawMarker(arrPoints) {
      if (markers.current) {
        markers.current.forEach((marker) => marker.setMap(null));
      }
      const newMarkers = [];
      arrPoints.forEach((arrPoint, idx) => {
        if (idx !== 0 && idx !== arrPoints.length - 1) return;

        const newMarker = new Tmapv2.Marker({
          position: arrPoint,
          map: mapInstance,
        });
        newMarkers.push(newMarker);
      });
      markers.current = newMarkers;
    }
    function resetMapBounds(arrPoints) {
      const latlngBounds = new window.Tmapv2.LatLngBounds();

      arrPoints.forEach((point) => {
        latlngBounds.extend(point);
      });
      mapInstance.fitBounds(latlngBounds, 100);
      // mapInstance.zoomOut();
    }
  }, [cours, mapInstance]);

  const resetCurrPosition = useCallback(() => {
    if (!currLatLng) return;

    if (currMarker.current) {
      // change position
      currMarker.current.setPosition(currLatLng);
    } else {
      // Make new one
      currMarker.current = new Tmapv2.Marker({
        position: currLatLng,
        map: mapInstance,
        iconHTML: `<div class="circle pulse blue"></div>`,
      });
    }
  }, [currLatLng, mapInstance]);

  const resetMapBounds = useCallback(() => {
    if (!currMarker.current) return;

    if (cours) {
      const latlngBounds = new window.Tmapv2.LatLngBounds();

      cours
        .filter((poi) => poi.geometry.type === "Point")
        .map(
          (poi) =>
            new window.Tmapv2.LatLng(
              poi.geometry.coordinates[1],
              poi.geometry.coordinates[0]
            )
        )
        .forEach((point) => {
          latlngBounds.extend(point);
        });

      latlngBounds.extend(currMarker.current.getPosition());

      const margin = {
        left: 100,
        top: 100,
        right: 100,
        bottom: 100,
      };
      mapInstance.fitBounds(latlngBounds, margin);
    } else {
      mapInstance.setCenter(currMarker.current.getPosition);
      mapInstance.setZoom(15);
    }
  }, [cours, mapInstance]);

  // [currLatLng] Hook
  useEffect(() => {
    if (currLatLng && currMarker.current) {
      resetCurrPosition();
    }
  }, [currLatLng, resetCurrPosition]);

  return (
    <Box
      className="walkRoadPage"
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
      }}
    >
      <RouteList
        selectedRoad={selectedRoad}
        setSelectedRoad={setSelectedRoad}
      />
      <Box sx={{ flex: 1, position: "relative", overflow: "hidden" }}>
        {cours !== null && (
          <RouteNavigate
            coursName={selectedRoad?.stretNm ?? null}
            cours={cours}
            searchOption={searchOption}
            setSearchOption={setSearchOption}
          />
        )}
        <MapView mapInstance={mapInstance} setMapInstance={setMapInstance} />
        <ToCurrPositionBtn
          disabled={!currLatLng}
          onClick={() => {
            resetCurrPosition();
            resetMapBounds();
          }}
        />
        <DeletePolylineBtn
          onClick={() => {
            pathPolyline.current.setPath([]);
          }}
        />
      </Box>
    </Box>
  );
}
